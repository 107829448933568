import axios from 'axios';
import config from '../data/config';
import { globalResponseErrorHandler } from './globalResponseErrorHandler';
import { lastEditedUpdate } from 'store/slices/authSlice'

let store

export const injectStore = _store => {
  store = _store
}

const customAxios = axios.create({
  baseURL: config.apiUrl
});

const requestHandler = request => {
  const isAuthEndpoint = request.url.includes('auth')

  /* if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true')
    request.headers.mode = 'dev' */

  const bearerToken = store.getState().auth.token

  if (!bearerToken || isAuthEndpoint) return request

  request.headers.Authorization = `Bearer ${bearerToken}`;

  return Promise.resolve(request)
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request)
);

const responseHandler = (response) => {
  let lastEditedFromHeader = response.headers['last-edited-user-time']

  store.dispatch(lastEditedUpdate(lastEditedFromHeader || 0))
  return Promise.resolve(response);
};

const errorResponseHandler = (error) => {
  const preparedResponse = globalResponseErrorHandler(error, store)
  return Promise.reject(preparedResponse)
};

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorResponseHandler(error)
);

const storageAxiosTemp = axios.create({
  baseURL: config.storageUrl,
  responseType: 'blob'
});
const storageRequestHandler = request => {
  return Promise.resolve(request)
}

storageAxiosTemp.interceptors.request.use(
  (request) => (storageRequestHandler(request))
);

const storageResponseHandler = (response) => {
  return Promise.resolve(response);
};

const errorStorageResponseHandler = (error) => {
  const preparedResponse = globalResponseErrorHandler(error, store)
  return Promise.reject(preparedResponse)
};

storageAxiosTemp.interceptors.response.use(
  (response) => storageResponseHandler(response),
  (error) => errorStorageResponseHandler(error)
);

export const storageAxios = storageAxiosTemp
export default customAxios;