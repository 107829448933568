import React from "react";

class RootErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  handleClick = () => {
    window.localStorage.clear();
    window.location.reload();
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2>Oops, something went wrong. Please try reloading the page</h2>
          <button onClick={this.handleClick}>Clear storage and refresh</button>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default RootErrorBoundary;