import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import routes from 'data/routes';
import { appPaths } from 'data/enums/appPaths';
import { enumRoles, enumPermissions } from "data/enums/authorization";
import { selectAuthenticated, selectPermissions, selectRole } from 'store/slices/authSlice';

const { ADMIN, PUBLISHER, ADVERTISER } = enumRoles

export const getHomeRoute = (role, permissions = []) => {
  let pathPrefix = ''
  let homeModulePath = 'profile'

  switch (role) {
    case ADMIN: pathPrefix = 'admin'; break;
    case PUBLISHER: pathPrefix = 'publisher'; break;
    case ADVERTISER: pathPrefix = 'advertiser'; break;
    default: break;
  }

  if ([PUBLISHER, ADVERTISER].includes(role)) homeModulePath = 'dashboard'
  else if ([enumPermissions.ADMIN_DASHBOARD_ADVERTISER].every(permission => permissions.includes(permission))) {
    homeModulePath = 'dashboard/advertiser'
  } else if ([enumPermissions.ADMIN_DASHBOARD_PUBLISHER].every(permission => permissions.includes(permission))) {
    homeModulePath = 'dashboard/publisher'
  }
  else if ([enumPermissions.ADMIN_USERS_OVERVIEW_LIST].every(permission => permissions.includes(permission))) {
    homeModulePath = 'users/overview'
  }
  else homeModulePath = 'profile'
  return { pathPrefix, homeModulePath }
}

export const RequirePermission = ({ children, requiredPermissions, requiredRoles }) => {
  const permissions = useSelector(selectPermissions);
  const role = useSelector(selectRole);
  const authenticated = useSelector(selectAuthenticated)
  const location = useLocation();
  if (!authenticated) return <Redirect to={{ pathname: '/', state: { from: location } }} />
  let permissionValid = true;
  let roleValid = true;

  if (requiredPermissions && Array.isArray(requiredPermissions) && requiredPermissions.length > 0) {
    requiredPermissions.forEach(requiredPermission => {
      if (!permissions.includes(requiredPermission)) permissionValid = false
    });
  }

  if (requiredRoles && Array.isArray(requiredRoles) && requiredRoles.length > 0) {
    if (!requiredRoles.includes(role)) roleValid = false
  }

  const { pathPrefix, homeModulePath } = getHomeRoute(role, permissions)
  let redirectLink = `/${pathPrefix}/${homeModulePath}/`

  if (!permissionValid || !roleValid) {
    return <Redirect to={{ pathname: redirectLink, state: { from: location } }} />
  }

  return children;
}

export const RedirectLoggedInUsers = ({ children }) => {
  let redirectLink = ''
  const location = useLocation();
  const role = useSelector(selectRole)
  const permissions = useSelector(selectPermissions);
  const { pathPrefix, homeModulePath } = getHomeRoute(role, permissions)
  redirectLink = role ? `/${pathPrefix}/${homeModulePath}/` : ''
  if (redirectLink)
    return <Redirect to={{ pathname: redirectLink, state: { from: location } }} />;
  return children
}

export const Redirect404 = () => {
  const location = useLocation();
  const authenticated = useSelector(selectAuthenticated)
  const role = useSelector(selectRole)
  const permissions = useSelector(selectPermissions);
  const { pathPrefix, homeModulePath } = getHomeRoute(role, permissions)
  let redirectLink = authenticated && role ? `/${pathPrefix}/${homeModulePath}/` : appPaths.LOGIN

  return <Redirect to={{ pathname: redirectLink, state: { from: location } }} />;

}

export const separateRoutes = (keys) => {
  const separatedRoutes = []
  routes.forEach(rootRoute => {
    rootRoute.modules.forEach(moduleRoute => {
      if (!moduleRoute.routes) {
        if (keys.includes(moduleRoute.id)) {
          separatedRoutes.push(moduleRoute)
        }
      } else {
        moduleRoute.routes.forEach(submoduleRoute => {
          if (keys.includes(submoduleRoute.id)) {
            separatedRoutes.push(submoduleRoute)
          }
        });
      }
    });
  });

  return separatedRoutes
}