import { useMemo, useRef } from "react"
import { Box, useTheme } from "@mui/material"
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { CSSTransition } from "react-transition-group";

import styles from './index.module.scss'

const loader = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0,0);
  }
  to {
    opacity: 0;
    transform: scale(1,1);
  }
`;


const Loader1 = styled(Box)(({ theme }) => ({
  opacity: 0,
  position: 'absolute', backgroundColor: theme.palette.primary.main,
  width: 100, height: 100, borderRadius: '100%',
  animation: `${loader} 1.6s infinite ease`
}));

const Loader2 = styled(Box)(({ theme }) => ({
  opacity: 0,
  position: 'absolute', backgroundColor: theme.palette.primary.main,
  width: 100, height: 100, borderRadius: '100%',
  animation: `${loader} 1.6s infinite ease 0.7s`
}));

const SplashScreen = ({ suspenseMounted }) => {

  const theme = useTheme()

  const nodeRef = useRef(null)

  const entryTransitionDuration = useMemo(() => theme.transitions.duration.enteringScreen, [theme])
  const exitTransitionDuration = useMemo(() => theme.transitions.duration.leavingScreen, [theme])

  const timeOut = useMemo(() => ({
    appear: entryTransitionDuration,
    enter: entryTransitionDuration,
    exit: exitTransitionDuration
  }), [entryTransitionDuration, exitTransitionDuration])

  const transitionStyles = useMemo(() => ({
    entering: { transition: theme.transitions.create(['opacity'], { duration: entryTransitionDuration }) },
    entered: { transition: theme.transitions.create(['opacity'], { duration: exitTransitionDuration }) },
    exiting: { transition: theme.transitions.create(['opacity'], { duration: exitTransitionDuration }) },
    exited: { transition: theme.transitions.create(['opacity'], { duration: exitTransitionDuration }) },
  }), [entryTransitionDuration, exitTransitionDuration, theme])

  return (
    <CSSTransition in={!!suspenseMounted} timeout={timeOut} nodeRef={nodeRef} unmountOnExit
      appear={true} enter={true} exit={true}
      classNames={{
        appear: styles.appear, appearActive: styles.appearActive, appearDone: styles.appearDone,
        enter: styles.enter, enterActive: styles.enterActive, enterDone: styles.enterDone,
        exit: styles.exit, exitActive: styles.exitActive, exitDone: styles.exitDone,
      }}>
      {state => {
        return (
          <Box ref={nodeRef} className={styles.default} sx={{
            position: 'absolute', zIndex: 60, top: 0, right: 0, bottom: 0, left: 0,
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            backgroundColor: theme.palette.menu.background.main,
            ...transitionStyles[state]
          }} >
            <Loader1 />
            <Loader2 />
          </Box>
        )
      }}
    </CSSTransition>

  )
}

export default SplashScreen