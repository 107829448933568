export const capitalizeWord = (str) =>
  typeof str === 'string' || str instanceof String ?
    `${str.charAt(0).toUpperCase()}${str.slice(1)}` : str

export const convertToLowerCase = (str) =>
  typeof str === 'string' || str instanceof String ?
    str.toLowerCase() : str


export const capitalize = (str) => {
  if (str) {
    let result = str;
    try {
      let temp = str.toLowerCase()
      result = temp.charAt(0).toUpperCase() + temp.slice(1);
    } catch (e) {
      result = str
    }
    return result
  } else {
    return str
  }

}