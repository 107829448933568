import { currentPersistedModuleVersion } from "data/config"

export const persistedModuleVersionMatches = () => {
  const persistedModuleConfig = localStorage.getItem('persistedModuleConfig');
  if (!persistedModuleConfig) return true;

  const parsedPersistedModuleConfig = JSON.parse(persistedModuleConfig);
  if (parsedPersistedModuleConfig.version !== currentPersistedModuleVersion) return false;

  return true;
}


/* EXAMPLE OF A MIGRATION FUNCTION */

/* const migrateFromV1ToV2 = (oldData) => {
  const newPersistedModuleConfigData = {}

  Object.keys(oldData).forEach(key => {
    const module = oldData[key];
    const newModule = {};
    Object.keys(module).forEach(moduleKey => {
      const bookmark = module[moduleKey];
      const { order, ...rest } = bookmark;
      newModule[moduleKey] = rest
    })
    newPersistedModuleConfigData[key] = newModule
  })

  return {
    version: currentPersistedModuleVersion,
    modules: newPersistedModuleConfigData
  }
} */

/* THE NAMING OF THE MIGRATIONS KEY IS IMPORTANT  IT SHOULD go from${oldVersion}to${newVersion}*/
const migrations = {
  /* from1to2: migrateFromV1ToV2 */
}

export const migratePersistedModuleVersion = () => {
  const persistedModuleConfig = localStorage.getItem('persistedModuleConfig');
  if (!persistedModuleConfig) throw new Error('Module config not found in local storage');

  const parsedPersistedModuleConfig = JSON.parse(persistedModuleConfig);
  const usersCurrentVersion = parsedPersistedModuleConfig.version;

  const migrationFn = migrations[`from${usersCurrentVersion}to${currentPersistedModuleVersion}`];
  if (!migrationFn) throw new Error('Incorrect version supplied or migration function not found');

  const newData = migrationFn(parsedPersistedModuleConfig.modules)

  localStorage.setItem('persistedModuleConfig', JSON.stringify(newData));
}