import axios, { storageAxios } from './axios'

const apiVersion = 'v1'

const requestSettings = async (searchObject, options = {}) => {
  const { signal } = options
  const apiPath = 'user.settings'
  const apiAction = 'get'
  const url = `${apiVersion}/${apiPath}/${apiAction}/`

  return new Promise((resolve, reject) => {
    axios.get(url, { signal })
      .then(res => { resolve(res?.data || null) })
      .catch(error => reject(error))
  })
}

const requestSessionInfo = async (searchObject, options = {}) => {
  const { signal } = options
  const apiPath = 'session'
  const apiAction = 'info'
  let url = `${apiVersion}/${apiPath}/${apiAction}/`

  return new Promise((resolve, reject) => {
    axios.get(url, { signal })
      .then(res => { resolve(res?.data || null) })
      .catch(error => reject(error))
  })
}

const resendEmailVerification = async (searchObject, options = {}) => {
  const { signal } = options
  const apiPath = 'publisher.profile'
  const apiAction = 'resend-verification-email'
  const url = `${apiVersion}/${apiPath}/${apiAction}/`

  return new Promise((resolve, reject) => {
    axios
      .get(url, { signal })
      .then((res) => resolve(res))
      .catch(err => reject(err))
  })
}

const loginWithPassword = async ({ username, password }) => {
  const url = `${apiVersion}/auth/`
  const authorizationData = `${username}:${password}`
  const authorization = Buffer.from(authorizationData, 'utf8').toString('base64')
  const options = {
    headers: {
      Authorization: `Basic ${authorization}`
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, options)
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  })

}

const loginWithPin = async ({ username, password, pin }) => {
  const searchParams = new URLSearchParams({ username, password, pin })
  const url = `${apiVersion}/auth.pin/?${searchParams}`

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(res => resolve(res.data))
      .catch(err => reject(err))
  })
}

const logout = async () => {
  const apiAction = 'logout'
  let url = `${apiVersion}/${apiAction}/`

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

const getStorageFile = async ({ storagePath, filename }, options = {}) => {
  const { signal } = options

  const blobToBase64 = blob => {

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result)
      };
    });
  };

  const url = /* 'https://tools.monetizead.com/images/tea2.jpg' || */ `${storagePath ? `${storagePath}/` : ''}${filename}`

  return new Promise((resolve, reject) => {
    storageAxios.get(url, { signal })
      .then((res) => blobToBase64(res.data))
      .then(preparedFile => resolve(preparedFile))
      .catch(error => reject(error))
  })

}

const commonApi = { requestSettings, requestSessionInfo, resendEmailVerification, logout, loginWithPassword, loginWithPin, getStorageFile }

export default commonApi