const sendWebVitalsToGoogleAnalytics = ({ name, delta, value, id }) => {
    // Assumes the global `gtag()` function exists, see:
    // https://developers.google.com/analytics/devguides/collection/ga4
    window.gtag('event', name, {
        // Specific gtag ID
        send_to: process.env.REACT_APP_GOOGLE_PERFORMANCE_ANALYTICS_ID,
        // Built-in params:
        value: delta, // Use `delta` so the value can be summed.
        // Custom params:
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.

        // OPTIONAL: any additional params or debug info here.
        // See: https://web.dev/debug-performance-in-the-field/
        // metric_rating: 'good' | 'needs-improvement' | 'poor',
        // debug_info: '...',
        // ...
    });
}

export default sendWebVitalsToGoogleAnalytics