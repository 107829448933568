import translations from "data/localization"
import { sessionExpired } from "store/slices/authSlice"
import { createApiMessage } from "store/slices/snackbarSlice"

const loginApi = (url = '') => url.includes('/auth/') || url.includes('/auth.pin/')

export const parseErrorMessage = ({ axiosCode, customCode, internalCode, responseStatus, language }) => {
  const defaultLanguage = 'EN'
  return translations[language || defaultLanguage].RequestErrors.custom[customCode]
    || translations[language || defaultLanguage].RequestErrors.internal[internalCode]
    || translations[language || defaultLanguage].RequestErrors.network[responseStatus]
    || translations[language || defaultLanguage].RequestErrors.browser[axiosCode]
    || translations[language || defaultLanguage].RequestErrors.default
}

export const apiErrorSnackbarOptions = (key = 0) => ({
  variant: 'error', key, anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
})

export const globalResponseErrorHandler = (error, store) => {

  const requestUrl = error?.config?.url

  const axiosCode = error?.code || null
  const responseStatus = error?.response?.status || null
  const internalCode = error?.response?.data?.code || 0

  if (internalCode === 0 && error?.message === 'canceled') return 'canceled'


  let message = parseErrorMessage({ internalCode, responseStatus, axiosCode })
  console.warn({ title: 'Global error handler', axiosCode, internalCode, responseStatus, message, error })

  const snackbarSetup = (message, key) => {
    const tempKey = key || new Date().getTime() + Math.random()
    store.dispatch(createApiMessage({ message, key: tempKey, options: apiErrorSnackbarOptions(tempKey) }))
  }

  if (internalCode === 8) {
    const field = error?.response?.data?.message
    if (field) return ({ message, field })
  }
  if (internalCode === 9) {
    const field = error?.response?.data?.message
    if (field) return ({ message, field })
  }

  // if(internalCode === 82) {
  //   const field = error?.response?.data?.message
  //   // if (field) return ({ message, field })
  //   // return error?.response?.data?.errors?.timestamp_last_created_token
  //   return 'neki polusaj'
  // }

  switch (responseStatus) {
    case 401:
      if (!loginApi(requestUrl)) {
        store.dispatch(sessionExpired())
        snackbarSetup(message, 'unauthenticated')
      } else { snackbarSetup(message) }
      break;

    default:
      snackbarSetup(message)
      break;
  }

  return ({ message })
}