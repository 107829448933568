import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notification: 0,
  widgetNotification: 0,
  usersNotification: 0
};

export const notificationReducer = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    changeNotification: (state, payload) => {
      state.notification = payload
    },
    changeWidgetNotification: (state, payload) => {
      state.widgetNotification = payload
    },
    changeUsersNotification: (state, payload) => {
      state.usersNotification = payload
    }
  },
});

export const { changeNotification, changeWidgetNotification, changeUsersNotification } = notificationReducer.actions;

export const selectNotification = (state) => state.notification.notification
export const selectWidgetNotification = (state) => state.notification.widgetNotification
export const selectUsersNotification = (state) => state.notification.usersNotification

export default notificationReducer.reducer;