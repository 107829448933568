import { Login } from "@mui/icons-material";
import { constructedPermissionTree } from "../../data/enums/authorization";

export const convertPermissionsToFlatArray = (permissions) => {
  const response = []
  if (!permissions) return response

  const keys = Object.keys(permissions)

  keys.forEach(key => {
    const modulePermissions = permissions[key]

    modulePermissions.forEach(permission => {
      response.push(`${key}/${permission}`)
    });
  });
  return response
}

export const convertPermissionsFromFlatArray = (permissions) => {
  const response = {}
  Object.keys(constructedPermissionTree).forEach(index => {
    response[index] = []
  });
  permissions.forEach(permission => {
    const [index, action] = permission.split('/')
    if (response[index]) response[index].push(action)
  });

  return response
}