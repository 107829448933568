import { createSlice } from '@reduxjs/toolkit';

const states = {
  v1: {
    userDefined: {},
  }
}

let initialState = states.v1

export const preselectMigration = {
  1: state => state.v1
}

export const tableReducer = createSlice({
  name: 'preselect',
  initialState,
  reducers: {
    setUserDefinedPreselect: (state, action) => {
      const { payload } = action
      const { moduleIndex, preselectIndex, option } = payload

      if (!state.userDefined[moduleIndex]) {
        state.userDefined = {
          ...state.userDefined,
          [moduleIndex]: { [preselectIndex]: { options: [option] } }
        }
      } else if (!state.userDefined[moduleIndex][preselectIndex]) {
        state.userDefined[moduleIndex] = {
          ...state.userDefined[moduleIndex],
          [preselectIndex]: { options: [option] }
        }
      } else {
        state.userDefined[moduleIndex][preselectIndex] = {
          ...state.userDefined[moduleIndex][preselectIndex],
          options: [...state.userDefined[moduleIndex][preselectIndex].options, option]
        }
      }
    },
    removeUserDefinedPreselect: (state, action) => {
      const { payload } = action
      const { moduleIndex, preselectIndex, option } = payload
      if (!state.userDefined[moduleIndex]
        || !state.userDefined[moduleIndex][preselectIndex]
        || !state.userDefined[moduleIndex][preselectIndex].options) return

      state.userDefined[moduleIndex][preselectIndex] = {
        ...state.userDefined[moduleIndex][preselectIndex],
        options: [...state.userDefined[moduleIndex][preselectIndex].options.filter(item => item.id !== option.id)]
      }
    }
  },
});

export const { setUserDefinedPreselect, removeUserDefinedPreselect } = tableReducer.actions;

export const selectUserDefinedPreselects = (state) => state.preselect.userDefined;


export const selectAddUserPreselect = (state) => /* state.preselect.addUser */[];
export const selectEditUserPreselect = (state) => /* state.preselect.editUser */[];
export const selectPermissionsUserPreselect = (state) => /* state.preselect.permissionsUser */[];
export const selectAddCampaignPreselect = (state) => /* state.preselect.addCampaign */[];
export const selectDefaultPreselects = (state) => /* state.preselect.defaults */[];
// export const selectUserDefinedPreselects = (state) => state.preselect.userDefined[];

export default tableReducer.reducer;