import { apiPaths, apiActions } from "./db"

export const enumRoles = { ADMIN: 1, PUBLISHER: 5, ADVERTISER: 6, BAN: 99 }

const enumPermissionActions = { ...apiActions }
const EPA = enumPermissionActions

export const enumPermissionModules = { ...apiPaths }
const EPM = enumPermissionModules

export const enumPermissions = {
  // Admin - Permissions
  ADMIN_PERMISSIONS_GET: `${EPM.ADMIN_PERMISSIONS}/${EPA.GET}`,
  ADMIN_PERMISSIONS_UPDATE: `${EPM.ADMIN_PERMISSIONS}/${EPA.UPDATE}`,

  // Admin - Dashboard
  ADMIN_DASHBOARD_PUBLISHER: `${EPM.ADMIN_DASHBOARD}/${EPA.PUBLISHER}`,
  ADMIN_DASHBOARD_ADVERTISER: `${EPM.ADMIN_DASHBOARD}/${EPA.ADVERTISER}`,

  ADMIN_FAST_LOGIN_CHECK: `${EPM.ADMIN_FAST_LOGIN}/${EPA.CHECK}`,
  ADMIN_FAST_LOGIN_CREATE: `${EPM.ADMIN_FAST_LOGIN}/${EPA.CREATE}`,

  // admin - payments
  ADMIN_PAYMENTS_LIST: `${EPM.ADMIN_PAYMENTS}/${EPA.LIST}`,
  ADMIN_PAYMENTS_UPDATE: `${EPM.ADMIN_PAYMENTS}/${EPA.UPDATE}`,
  ADMIN_PAYMENTS_TOTAL: `${EPM.ADMIN_PAYMENTS}/${EPA.TOTAL}`,
  ADMIN_PAYMENTS_TOTAL_BY_METHOD: `${EPM.ADMIN_PAYMENTS}/${EPA.TOTAL_BY_METHOD}`,

  //Admin - Logs
  ADMIN_LOGS_QUEUE_EMAILS: `${EPM.ADMIN_LOGS}/${EPA.QUEUE_EMAIL}`,
  ADMIN_LOGS_REQUEST: `${EPM.ADMIN_LOGS}/${EPA.REQUEST}`,

  // Admin - Campaigns
  ADMIN_CAMPAIGNS_LIST: `${EPM.ADMIN_CAMPAIGNS}/${EPA.LIST}`,
  ADMIN_CAMPAIGNS_CREATE: `${EPM.ADMIN_CAMPAIGNS}/${EPA.CREATE}`,
  ADMIN_CAMPAIGNS_UPDATE: `${EPM.ADMIN_CAMPAIGNS}/${EPA.UPDATE}`,

  // Admin - Reports
  ADMIN_REPORT_DYNAMICS: `${EPM.ADMIN_REPORT}/${EPA.DYNAMICS}`,

  // Admin - Users
  ADMIN_USERS_LIST: `${EPM.ADMIN_USERS}/${EPA.LIST}`,
  ADMIN_USERS_CREATE: `${EPM.ADMIN_USERS}/${EPA.CREATE}`,
  ADMIN_USERS_UPDATE: `${EPM.ADMIN_USERS}/${EPA.UPDATE}`,
  ADMIN_USERS_DELETE: `${EPM.ADMIN_USERS}/${EPA.DELETE}`,

  // Admin - Countries bid limits
  ADMIN_COUNTRIES_BID_LIMITS_LIST: `${EPM.ADMIN_COUNTRIES_BID_LIMITS}/${EPA.LIST}`,
  ADMIN_COUNTRIES_BID_LIMITS_UPDATE: `${EPM.ADMIN_COUNTRIES_BID_LIMITS}/${EPA.UPDATE}`,
  ADMIN_COUNTRIES_BID_LIMITS_CREATE: `${EPM.ADMIN_COUNTRIES_BID_LIMITS}/${EPA.CREATE}`,
  ADMIN_COUNTRIES_BID_LIMITS_DELETE: `${EPM.ADMIN_COUNTRIES_BID_LIMITS}/${EPA.DELETE}`,


  // Admin - Users Overdraft
  ADMIN_USERS_OVERDRAFT_LIST: `${EPM.ADMIN_USERS_OVERDRAFT}/${EPA.LIST}`,
  ADMIN_USERS_OVERDRAFT_CREATE: `${EPM.ADMIN_USERS_OVERDRAFT}/${EPA.CREATE}`,
  ADMIN_USERS_OVERDRAFT_UPDATE: `${EPM.ADMIN_USERS_OVERDRAFT}/${EPA.UPDATE}`,
  ADMIN_USERS_OVERDRAFT_DELETE: `${EPM.ADMIN_USERS_OVERDRAFT}/${EPA.DELETE}`,

  // Admin - Users Overdraft
  ADMIN_USERS_OVERDRAFT_LIST: `${EPM.ADMIN_USERS_OVERDRAFT}/${EPA.LIST}`,
  ADMIN_USERS_OVERDRAFT_CREATE: `${EPM.ADMIN_USERS_OVERDRAFT}/${EPA.CREATE}`,
  ADMIN_USERS_OVERDRAFT_UPDATE: `${EPM.ADMIN_USERS_OVERDRAFT}/${EPA.UPDATE}`,
  ADMIN_USERS_OVERDRAFT_DELETE: `${EPM.ADMIN_USERS_OVERDRAFT}/${EPA.DELETE}`,

  // Admin - Users Overview
  ADMIN_USERS_OVERVIEW_LIST: `${EPM.ADMIN_USERS_OVERVIEW}/${EPA.LIST}`,
  ADMIN_USERS_OVERVIEW_TOTAL: `${EPM.ADMIN_USERS_OVERVIEW}/${EPA.TOTAL}`,

  // Admin - Click settings
  ADMIN_CLICK_SETTINGS_LIST: `${EPM.ADMIN_CLICK_SETTINGS}/${EPA.LIST}`,
  ADMIN_CLICK_SETTINGS_CREATE: `${EPM.ADMIN_CLICK_SETTINGS}/${EPA.CREATE}`,

  // Admin - Subid settings
  ADMIN_SUBID_LIST: `${EPM.ADMIN_SUBID}/${EPA.LIST}`,
  ADMIN_SUBID_CREATE: `${EPM.ADMIN_SUBID}/${EPA.CREATE}`,
  ADMIN_SUBID_UPDATE: `${EPM.ADMIN_SUBID}/${EPA.UPDATE}`,
  ADMIN_SUBID_DELETE: `${EPM.ADMIN_SUBID}/${EPA.DELETE}`,

  // Admin - Bids
  ADMIN_BIDS_LIST: `${EPM.ADMIN_BIDS}/${EPA.LIST}`,
  ADMIN_BIDS_CREATE: `${EPM.ADMIN_BIDS}/${EPA.CREATE}`,
  ADMIN_BIDS_UPDATE: `${EPM.ADMIN_BIDS}/${EPA.UPDATE}`,
  ADMIN_BIDS_DELETE: `${EPM.ADMIN_BIDS}/${EPA.DELETE}`,

  // Admin - Topups
  ADMIN_TOPUPS_LIST: `${EPM.ADMIN_TOPUPS}/${EPA.LIST}`,
  ADMIN_TOPUPS_CREATE: `${EPM.ADMIN_TOPUPS}/${EPA.CREATE}`,
  ADMIN_TOPUPS_UPDATE: `${EPM.ADMIN_TOPUPS}/${EPA.UPDATE}`,
  ADMIN_TOPUPS_DELETE: `${EPM.ADMIN_TOPUPS}/${EPA.DELETE}`,

  // Admin - Topups - Coupons
  ADMIN_TOPUPS_COUPONS_LIST: `${EPM.ADMIN_TOPUPS_COUPONS}/${EPA.LIST}`,
  ADMIN_TOPUPS_COUPONS_CREATE: `${EPM.ADMIN_TOPUPS_COUPONS}/${EPA.CREATE}`,
  ADMIN_TOPUPS_COUPONS_UPDATE: `${EPM.ADMIN_TOPUPS_COUPONS}/${EPA.UPDATE}`,
  ADMIN_TOPUPS_COUPONS_DELETE: `${EPM.ADMIN_TOPUPS_COUPONS}/${EPA.DELETE}`,

  // Admin - Sites Groups
  ADMIN_SITES_GROUPS_LIST: `${EPM.ADMIN_SITES_GROUPS}/${EPA.LIST}`,
  ADMIN_SITES_GROUPS_CREATE: `${EPM.ADMIN_SITES_GROUPS}/${EPA.CREATE}`,
  ADMIN_SITES_GROUPS_UPDATE: `${EPM.ADMIN_SITES_GROUPS}/${EPA.UPDATE}`,
  ADMIN_SITES_GROUPS_DELETE: `${EPM.ADMIN_SITES_GROUPS}/${EPA.DELETE}`,
  ADMIN_SITES_GROUPS_ASSIGN: `${EPM.ADMIN_SITES_GROUPS}/${EPA.ASSIGN}`,
  ADMIN_SITES_GROUPS_UNASSIGN: `${EPM.ADMIN_SITES_GROUPS}/${EPA.UNASSIGN}`,
  ADMIN_SITES_GROUPS_LIST_ASSIGNED: `${EPM.ADMIN_SITES_GROUPS}/${EPA.LIST_ASSIGNED}`,
  ADMIN_SITES_GROUPS_LIST_NOT_ASSIGNED: `${EPM.ADMIN_SITES_GROUPS}/${EPA.LIST_NOT_ASSIGNED}`,

  // Admin - Users Overview
  ADMIN_USERS_OVERVIEW_LIST: `${EPM.ADMIN_USERS_OVERVIEW}/${EPA.LIST}`,

  // Admin - Sites
  ADMIN_SITES_LIST: `${EPM.ADMIN_SITES}/${EPA.LIST}`,
  ADMIN_SITES_UPDATE: `${EPM.ADMIN_SITES}/${EPA.UPDATE}`,

  // Admin - Widgets
  ADMIN_WIDGETS_LIST: `${EPM.ADMIN_WIDGETS}/${EPA.LIST}`,
  ADMIN_WIDGETS_UPDATE: `${EPM.ADMIN_WIDGETS}/${EPA.UPDATE}`,
  ADMIN_WIDGETS_DELETE: `${EPM.ADMIN_WIDGETS}/${EPA.DELETE}`,

  // Admin - Click log
  ADMIN_CLICK_LOG_LIST: `${EPM.ADMIN_CLICK_LOG}/${EPA.LIST}`,

  // Admin - Statistic
  ADMIN_STATISTIC_LIST: `${EPM.ADMIN_STATISTIC}/${EPA.LIST}`,

  // Admin - Login history
  ADMIN_LOGIN_HISTORY_LIST: `${EPM.ADMIN_LOGIN_HISTORY}/${EPA.LIST}`,

  // Admin - System notification
  ADMIN_SYSTEM_NOTIFICATION_LIST: `${EPM.ADMIN_SYSTEM_NOTIFICATION}/${EPA.LIST}`,
  ADMIN_SYSTEM_NOTIFICATION_CREATE: `${EPM.ADMIN_SYSTEM_NOTIFICATION}/${EPA.CREATE}`,
  ADMIN_SYSTEM_NOTIFICATION_UPDATE: `${EPM.ADMIN_SYSTEM_NOTIFICATION}/${EPA.UPDATE}`,
  ADMIN_SYSTEM_NOTIFICATION_DELETE: `${EPM.ADMIN_SYSTEM_NOTIFICATION}/${EPA.DELETE}`,

  // Admin - Users Limits
  ADMIN_USERS_LIMITS_LIST: `${EPM.ADMIN_USERS_LIMITS}/${EPA.LIST}`,
  ADMIN_USERS_LIMITS_CREATE: `${EPM.ADMIN_USERS_LIMITS}/${EPA.CREATE}`,
  ADMIN_USERS_LIMITS_UPDATE: `${EPM.ADMIN_USERS_LIMITS}/${EPA.UPDATE}`,
  ADMIN_USERS_LIMITS_DELETE: `${EPM.ADMIN_USERS_LIMITS}/${EPA.DELETE}`,

  // Admin - Overview
  ADMIN_OVERVIEW_LIST: `${EPM.ADMIN_OVERVIEW}/${EPA.LIST}`,
  ADMIN_OVERVIEW_TOTAL: `${EPM.ADMIN_OVERVIEW}/${EPA.TOTAL}`,

  // Admin - Campaign Single
  ADMIN_CAMPAIGNS_AUDIENCE_GET: `${EPM.ADMIN_CAMPAIGNS_AUDIENCE}/${EPA.GET}`,
  ADMIN_CAMPAIGNS_AUDIENCE_UPDATE: `${EPM.ADMIN_CAMPAIGNS_AUDIENCE}/${EPA.UPDATE}`,
  ADMIN_CAMPAIGNS_LIMITS_CREATE: `${EPM.ADMIN_CAMPAIGNS_LIMITS}/${EPA.CREATE}`,
  ADMIN_CAMPAIGNS_LIMITS_LIST: `${EPM.ADMIN_CAMPAIGNS_LIMITS}/${EPA.LIST}`,
  ADMIN_CAMPAIGNS_LIMITS_DELETE: `${EPM.ADMIN_CAMPAIGNS_LIMITS}/${EPA.DELETE}`,
  ADMIN_CAMPAIGNS_CREATIVES_LIST: `${EPM.ADMIN_CAMPAIGNS_CREATIVES}/${EPA.LIST}`,
  ADMIN_CAMPAIGNS_CREATIVES_CREATE: `${EPM.ADMIN_CAMPAIGNS_CREATIVES}/${EPA.CREATE}`,
  ADMIN_CAMPAIGNS_CREATIVES_UPDATE: `${EPM.ADMIN_CAMPAIGNS_CREATIVES}/${EPA.UPDATE}`,
  ADMIN_CAMPAIGNS_PRICING_CREATE: `${EPM.ADMIN_CAMPAIGNS_PRICING}/${EPA.CREATE}`,
  ADMIN_CAMPAIGNS_PRICING_UPDATE: `${EPM.ADMIN_CAMPAIGNS_PRICING}/${EPA.UPDATE}`,
  ADMIN_CAMPAIGNS_PRICING_LIST: `${EPM.ADMIN_CAMPAIGNS_PRICING}/${EPA.LIST}`,
  ADMIN_CAMPAIGNS_PRICING_DELETE: `${EPM.ADMIN_CAMPAIGNS_PRICING}/${EPA.DELETE}`,

  // Admin - Unapproved creatives
  ADMIN_UNAPPROVED_CREATIVES_LIST: `${EPM.ADMIN_UNAPPROVED_CREATIVES}/${EPA.LIST}`,
  ADMIN_UNAPPROVED_CREATIVES_TOTAL: `${EPM.ADMIN_UNAPPROVED_CREATIVES}/${EPA.TOTAL}`,

  // Admin - System emails
  ADMIN_SYSTEM_EMAILS_CREATE: `${EPM.ADMIN_SYSTEM_EMAILS}/${EPA.CREATE}`,
  ADMIN_SYSTEM_EMAILS_UPDATE: `${EPM.ADMIN_SYSTEM_EMAILS}/${EPA.UPDATE}`,
  ADMIN_SYSTEM_EMAILS_DELETE: `${EPM.ADMIN_SYSTEM_EMAILS}/${EPA.DELETE}`,
  ADMIN_SYSTEM_EMAILS_LIST: `${EPM.ADMIN_SYSTEM_EMAILS}/${EPA.LIST}`,
  ADMIN_SYSTEM_EMAILS_SEND_TEST: `${EPM.ADMIN_SYSTEM_EMAILS}/${EPA.SEND_TEST}`,

  // Admin - Reports
  ADMIN_REPORT_DYNAMICS: `${EPM.ADMIN_REPORT}/${EPA.DYNAMICS}`,

  // Admin - Change-log
  ADMIN_CHANGELOG_LIST: `${EPM.ADMIN_CHANGELOG}/${EPA.LIST}`,
  ADMIN_CHANGELOG_CREATE: `${EPM.ADMIN_CHANGELOG}/${EPA.CREATE}`,
  ADMIN_CHANGELOG_DELETE: `${EPM.ADMIN_CHANGELOG}/${EPA.DELETE}`,
  ADMIN_CHANGELOG_UPDATE: `${EPM.ADMIN_CHANGELOG}/${EPA.UPDATE}`,

  ADMIN_EXPENSES_LIST: `${EPM.ADMIN_EXPENSES}/${EPA.LIST}`,

  ADMIN_SSP_REVENUE_LIST: `${EPM.ADMIN_SSP_REVENUE}/${EPA.LIST}`,
  ADMIN_SSP_REVENUE_CREATE: `${EPM.ADMIN_SSP_REVENUE}/${EPA.CREATE}`,
  ADMIN_SSP_REVENUE_UPDATE: `${EPM.ADMIN_SSP_REVENUE}/${EPA.UPDATE}`,

  // Settings
  SETTINGS_GET: `${EPM.SETTINGS}/${EPA.GET}`,
  SETTINGS_UPDATE: `${EPM.SETTINGS}/${EPA.UPDATE}`,
}
const EP = enumPermissions;

export const constructedPermissionTree = {

  [EPM.ADMIN_USERS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_USERS_LIST, EP.ADMIN_USERS_CREATE, EP.ADMIN_USERS_UPDATE, EP.ADMIN_USERS_DELETE]
  },
  [EPM.ADMIN_COUNTRIES_BID_LIMITS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_COUNTRIES_BID_LIMITS_LIST, EP.ADMIN_COUNTRIES_BID_LIMITS_CREATE, EP.ADMIN_COUNTRIES_BID_LIMITS_UPDATE, EP.ADMIN_COUNTRIES_BID_LIMITS_DELETE]
  },
  [EPM.ADMIN_USERS_OVERDRAFT]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_USERS_OVERDRAFT_LIST, EP.ADMIN_USERS_OVERDRAFT_CREATE, EP.ADMIN_USERS_OVERDRAFT_UPDATE, EP.ADMIN_USERS_OVERDRAFT_DELETE]
  },
  [EPM.ADMIN_CAMPAIGNS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE],
    modified: [EP.ADMIN_CAMPAIGNS_LIST, EP.ADMIN_CAMPAIGNS_CREATE, EP.ADMIN_CAMPAIGNS_UPDATE]
  },
  [EPM.ADMIN_LOGS]: {
    original: [EPA.QUEUE_EMAIL, EPA.REQUEST],
    modified: [EP.ADMIN_LOGS_QUEUE_EMAILS, EP.ADMIN_LOGS_REQUEST]
  },
  [EPM.ADMIN_REPORT]: {
    original: [EPA.DYNAMICS],
    modified: [EP.ADMIN_REPORT_DYNAMICS]
  },
  [EPM.ADMIN_SUBID]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_SUBID_LIST, EP.ADMIN_SUBID_CREATE, EP.ADMIN_SUBID_UPDATE, EP.ADMIN_SUBID_DELETE]
  },
  [EPM.ADMIN_SITES_GROUPS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE, EPA.ASSIGN, EPA.UNASSIGN, EPA.LIST_ASSIGNED, EPA.LIST_NOT_ASSIGNED],
    modified: [EP.ADMIN_SITES_GROUPS_LIST, EP.ADMIN_SITES_GROUPS_CREATE, EP.ADMIN_SITES_GROUPS_UPDATE, EP.ADMIN_SITES_GROUPS_DELETE, EP.ADMIN_SITES_GROUPS_ASSIGN, EP.ADMIN_SITES_GROUPS_UNASSIGN, EP.ADMIN_SITES_GROUPS_LIST_ASSIGNED, EP.ADMIN_SITES_GROUPS_LIST_NOT_ASSIGNED]
  },
  [EPM.ADMIN_USERS_OVERVIEW]: {
    original: [EPA.LIST, EPA.TOTAL],
    modified: [EP.ADMIN_USERS_OVERVIEW_LIST, EP.ADMIN_USERS_OVERVIEW_TOTAL]
  },
  [EPM.ADMIN_PERMISSIONS]: {
    original: [EPA.GET, EPA.UPDATE],
    modified: [EP.ADMIN_PERMISSIONS_GET, EP.ADMIN_PERMISSIONS_UPDATE]
  },

  [EPM.ADMIN_USERS_LIMITS]: {
    original: [EPA.LIST, EPA.UPDATE, EPA.CREATE, EPA.DELETE],
    modified: [EP.ADMIN_USERS_LIMITS_LIST, EP.ADMIN_USERS_LIMITS_UPDATE, EP.ADMIN_USERS_LIMITS_DELETE]
  },

  [EPM.ADMIN_DASHBOARD]: {
    original: [EPA.PUBLISHER, EPA.ADVERTISER],
    modified: [EP.ADMIN_DASHBOARD_PUBLISHER, EP.ADMIN_DASHBOARD_ADVERTISER]
  },

  [EPM.ADMIN_PAYMENTS]: {
    original: [EPA.LIST, EPA.UPDATE, EPA.TOTAL, EPA.TOTAL_BY_METHOD],
    modified: [EP.ADMIN_PAYMENTS_LIST, EP.ADMIN_PAYMENTS_UPDATE, EP.ADMIN_PAYMENTS_TOTAL, EP.ADMIN_PAYMENTS_TOTAL_BY_METHOD]
  },

  [EPM.ADMIN_SITES]: {
    original: [EPA.LIST, EPA.UPDATE],
    modified: [EP.ADMIN_SITES_LIST, EP.ADMIN_SITES_UPDATE]
  },
  [EPM.ADMIN_CLICK_LOG]: {
    original: [EPA.LIST],
    modified: [EP.ADMIN_CLICK_LOG_LIST]
  },
  [EPM.ADMIN_STATISTIC]: {
    original: [EPA.LIST],
    modified: [EP.ADMIN_STATISTIC_LIST]
  },
  [EPM.ADMIN_WIDGETS]: {
    original: [EPA.LIST, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_WIDGETS_LIST, EP.ADMIN_WIDGETS_UPDATE, EP.ADMIN_WIDGETS_DELETE]
  },

  [EPM.ADMIN_LOGIN_HISTORY]: {
    original: [EPA.LIST],
    modified: [EP.ADMIN_LOGIN_HISTORY_LIST]
  },
  [EPM.ADMIN_FAST_LOGIN]: {
    original: [EPA.CHECK, EPA.CREATE],
    modified: [EP.ADMIN_FAST_LOGIN_CHECK, EP.ADMIN_FAST_LOGIN_CREATE]
  },

  [EPM.ADMIN_SYSTEM_NOTIFICATION]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_SYSTEM_NOTIFICATION_LIST, EP.ADMIN_SYSTEM_NOTIFICATION_CREATE, EP.ADMIN_SYSTEM_NOTIFICATION_UPDATE, EP.ADMIN_SYSTEM_NOTIFICATION_DELETE]
  },

  [EPM.ADMIN_BIDS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_BIDS_LIST, EP.ADMIN_BIDS_CREATE, EP.ADMIN_BIDS_UPDATE, EP.ADMIN_BIDS_DELETE]
  },

  [EPM.ADMIN_TOPUPS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_TOPUPS_LIST, EP.ADMIN_TOPUPS_CREATE, EP.ADMIN_TOPUPS_UPDATE, EP.ADMIN_TOPUPS_DELETE]
  },

  [EPM.ADMIN_TOPUPS_COUPONS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_TOPUPS_COUPONS_LIST, EP.ADMIN_TOPUPS_COUPONS_CREATE, EP.ADMIN_TOPUPS_COUPONS_UPDATE, EP.ADMIN_TOPUPS_COUPONS_DELETE]
  },

  [EPM.ADMIN_OVERVIEW]: {
    original: [EPA.LIST, EPA.TOTAL],
    modified: [EP.ADMIN_OVERVIEW_LIST, EP.ADMIN_OVERVIEW_TOTAL]
  },
  [EPM.ADMIN_CLICK_SETTINGS]: {
    original: [EPA.LIST, EPA.CREATE],
    modified: [EP.ADMIN_CLICK_SETTINGS_LIST, EP.ADMIN_CLICK_SETTINGS_CREATE]
  },
  [EPM.ADMIN_CAMPAIGNS_CREATIVES]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE],
    modified: [EP.ADMIN_CAMPAIGNS_CREATIVES_LIST, EP.ADMIN_CAMPAIGNS_CREATIVES_CREATE, EP.ADMIN_CAMPAIGNS_CREATIVES_UPDATE]
  },
  [EPM.ADMIN_CAMPAIGNS_PRICING]: {
    original: [EPA.CREATE, EPA.LIST, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_CAMPAIGNS_PRICING_CREATE, EP.ADMIN_CAMPAIGNS_PRICING_LIST, EP.ADMIN_CAMPAIGNS_PRICING_UPDATE, EP.ADMIN_CAMPAIGNS_PRICING_DELETE]
  },
  [EPM.ADMIN_CAMPAIGNS_LIMITS]: {
    original: [EPA.CREATE, EPA.LIST, EPA.DELETE],
    modified: [EP.ADMIN_CAMPAIGNS_LIMITS_CREATE, EP.ADMIN_CAMPAIGNS_LIMITS_LIST, EP.ADMIN_CAMPAIGNS_LIMITS_DELETE]
  },
  [EPM.ADMIN_CAMPAIGNS_AUDIENCE]: {
    original: [EPA.GET, EPA.UPDATE],
    modified: [EP.ADMIN_CAMPAIGNS_AUDIENCE_GET, EP.ADMIN_CAMPAIGNS_AUDIENCE_UPDATE]
  },
  [EPM.ADMIN_UNAPPROVED_CREATIVES]: {
    original: [EPA.LIST, EPA.TOTAL],
    modified: [EP.ADMIN_UNAPPROVED_CREATIVES_LIST, EP.ADMIN_UNAPPROVED_CREATIVES_TOTAL]
  },
  // [EPM.SETTINGS]: {
  //   original: [EPA.GET, EPA.UPDATE],
  //   modified: [EP.SETTINGS_GET, EP.SETTINGS_UPDATE]
  // },
  [EPM.ADMIN_SYSTEM_EMAILS]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE, EPA.SEND_TEST],
    modified: [EP.ADMIN_SYSTEM_EMAILS_LIST, EP.ADMIN_SYSTEM_EMAILS_CREATE, EP.ADMIN_SYSTEM_EMAILS_UPDATE, EP.ADMIN_SYSTEM_EMAILS_DELETE, EP.ADMIN_SYSTEM_EMAILS_SEND_TEST]
  },
  [EPM.ADMIN_CHANGELOG]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE, EPA.DELETE],
    modified: [EP.ADMIN_CHANGELOG_LIST, EP.ADMIN_CHANGELOG_CREATE, EP.ADMIN_CHANGELOG_UPDATE, EP.ADMIN_CHANGELOG_DELETE]
  },
  [EPM.ADMIN_EXPENSES]: {
    original: [EPA.LIST],
    modified: [EP.ADMIN_EXPENSES_LIST]
  },
  [EPM.ADMIN_SSP_REVENUE]: {
    original: [EPA.LIST, EPA.CREATE, EPA.UPDATE],
    modified: [EP.ADMIN_SSP_REVENUE_LIST, EP.ADMIN_SSP_REVENUE_CREATE, EP.ADMIN_SSP_REVENUE_UPDATE]
  },
}
const CPT = constructedPermissionTree

export const adminModules = [
  EPM.ADMIN_DASHBOARD,
  EPM.ADMIN_REPORT,
  EPM.ADMIN_USERS,
  EPM.ADMIN_PERMISSIONS,
  EPM.ADMIN_CAMPAIGNS,
  EPM.ADMIN_PAYMENTS,
  EPM.ADMIN_SUBID,
  EPM.ADMIN_SITES_GROUPS,
  EPM.ADMIN_BIDS,
  EPM.ADMIN_TOPUPS,
  EPM.ADMIN_TOPUPS_COUPONS,
  EPM.ADMIN_USERS_OVERVIEW,
  EPM.ADMIN_STATISTIC,
  EPM.ADMIN_SITES,
  EPM.ADMIN_CLICK_LOG,
  EPM.ADMIN_WIDGETS,
  EPM.ADMIN_LOGIN_HISTORY,
  EPM.ADMIN_LOGS,
  EPM.ADMIN_FAST_LOGIN,
  EPM.ADMIN_SYSTEM_NOTIFICATION,
  EPM.ADMIN_OVERVIEW,
  EPM.ADMIN_CLICK_SETTINGS,
  EPM.ADMIN_USERS_OVERDRAFT,
  EPM.ADMIN_CAMPAIGNS_CREATIVES,
  EPM.ADMIN_CAMPAIGNS_AUDIENCE,
  EPM.ADMIN_CAMPAIGNS_LIMITS,
  EPM.ADMIN_CAMPAIGNS_PRICING,
  EPM.ADMIN_UNAPPROVED_CREATIVES,
  EPM.ADMIN_SYSTEM_EMAILS,
  EPM.ADMIN_USERS_LIMITS,
  EPM.ADMIN_COUNTRIES_BID_LIMITS,
  EPM.ADMIN_CHANGELOG,
  EPM.ADMIN_EXPENSES,
  EPM.ADMIN_SSP_REVENUE
]

// publisher stuffs

export const publisherModules = []

export const advertiserModules = []

export const adminActions = [
  ...CPT[EPM.ADMIN_USERS].modified,
  ...CPT[EPM.ADMIN_DASHBOARD].modified,
  ...CPT[EPM.ADMIN_CAMPAIGNS].modified,
  ...CPT[EPM.ADMIN_REPORT].modified,
  ...CPT[EPM.ADMIN_SUBID].modified,
  ...CPT[EPM.ADMIN_USERS_OVERVIEW].modified,
  ...CPT[EPM.ADMIN_PERMISSIONS].modified,
  ...CPT[EPM.ADMIN_STATISTIC].modified,
  ...CPT[EPM.ADMIN_CLICK_LOG].modified,
  ...CPT[EPM.ADMIN_SITES].modified,
  ...CPT[EPM.ADMIN_PAYMENTS].modified,
  ...CPT[EPM.ADMIN_WIDGETS].modified,
  ...CPT[EPM.ADMIN_LOGIN_HISTORY].modified,
  ...CPT[EPM.ADMIN_SITES_GROUPS].modified,
  ...CPT[EPM.ADMIN_LOGS].modified,
  ...CPT[EPM.ADMIN_FAST_LOGIN].modified,
  ...CPT[EPM.ADMIN_SYSTEM_NOTIFICATION].modified,
  ...CPT[EPM.ADMIN_OVERVIEW].modified,
  ...CPT[EPM.ADMIN_CLICK_SETTINGS].modified,
  ...CPT[EPM.ADMIN_TOPUPS].modified,
  ...CPT[EPM.ADMIN_TOPUPS_COUPONS].modified,
  ...CPT[EPM.ADMIN_BIDS].modified,
  ...CPT[EPM.ADMIN_USERS_OVERDRAFT].modified,
  ...CPT[EPM.ADMIN_CAMPAIGNS_CREATIVES].modified,
  ...CPT[EPM.ADMIN_CAMPAIGNS_AUDIENCE].modified,
  ...CPT[EPM.ADMIN_CAMPAIGNS_LIMITS].modified,
  ...CPT[EPM.ADMIN_CAMPAIGNS_PRICING].modified,
  ...CPT[EPM.ADMIN_SYSTEM_EMAILS].modified,
  ...CPT[EPM.ADMIN_COUNTRIES_BID_LIMITS].modified,
  ...CPT[EPM.ADMIN_USERS_LIMITS].modified,
  ...CPT[EPM.ADMIN_UNAPPROVED_CREATIVES].modified,
  ...CPT[EPM.ADMIN_CHANGELOG].modified,
  ...CPT[EPM.ADMIN_EXPENSES].modified,
  ...CPT[EPM.ADMIN_SSP_REVENUE].modified


]

export const commonModules = []
export const commonActions = []