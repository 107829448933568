import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  snackPack: []
};

export const snackbarBottomReducer = createSlice({
  name: 'snackbarBottom',
  initialState,
  reducers: {
    createSnackbarBottomSuccess: (state, action) => {
      state.snackPack = [...state.snackPack, { severity: 'success', message: action?.payload, key: new Date().getTime() }]
    },
    createSnackbarBottomError: (state, action) => {
      state.snackPack = [...state.snackPack, { severity: 'error', message: action?.payload, key: new Date().getTime() }]
    },
    removeSnackbarBottomMessage: (state) => {
      state.snackPack = state.snackPack.slice(1)
    }
  },
});

export const { createSnackbarBottomSuccess, createSnackbarBottomError, removeSnackbarBottomMessage } = snackbarBottomReducer.actions;

export const selectSnackbarBottomSnackPack = (state) => state.snackbarBottom.snackPack;


export default snackbarBottomReducer.reducer;