import { createSlice } from '@reduxjs/toolkit';

const states = {
  v1: {
    config: {
      default: {
        cellButton: true, filterEmptyColumns: false, dateLocale: '',
        tableDataParsing: true
      }
    }
  }
}

let initialState = states.v1

export const tableMigration = {
  1: () => states.v1,
}

export const tableReducer = createSlice({
  name: 'table',
  initialState,
  reducers: {
    changeTableConfig: (state, action) => {
      const { tableSliceIndex, data } = action.payload
      state.config[tableSliceIndex] = { ...state.config[tableSliceIndex], ...data }
    }
  },
});

export const { changeTableConfig } = tableReducer.actions;

export const selectTableConfig = (state) => state.table.config;


export default tableReducer.reducer;