import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { GlobalStyles, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import semiDarkTheme from 'data/mui/semiDarkTheme';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'store';

import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import { injectStore } from "api/axios";

import App from './App';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/900.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';

import './index.scss';
import sendWebVitalsToGoogleAnalytics from 'utilities/google/sendWebVitalsToGoogleAnalytics';

window.Buffer = window.Buffer || require("buffer").Buffer;

injectStore(store);

const LoadingMarkup = () => <div style={{ display: 'none' }}></div>;

const theme = semiDarkTheme

const globalStyles = {
  ':root': {
    '--color-primary': theme.palette.primary.main,
    '--color-secondary': theme.palette.secondary.main,
    '--color-success': theme.palette.success.main,
    '--color-error': theme.palette.error.main,
    '--color-background': theme.palette.background.main,
    '--border-radius': `${theme.shape.borderRadius}px`,
  },
}

const inputGlobalStyles = <GlobalStyles styles={globalStyles} />;

const root = createRoot(document.getElementById('root'))

TimeAgo.addDefaultLocale(en)

root.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingMarkup />} persistor={persistor}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          {inputGlobalStyles}
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendWebVitalsToGoogleAnalytics);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();